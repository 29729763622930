document.addEventListener('DOMContentLoaded', function () {


  // dropdown open and hide when click outside
  const dropdown = function (dropdownLinksSelector, dropdownInnerElementsSelector) {
    const dropdownLinks = document.querySelectorAll(dropdownLinksSelector);
    const dropdownInnerElements = document.querySelectorAll(dropdownInnerElementsSelector);

    function dropdownClick(e) {
      const targetElement = document.getElementById(this.hash.substr(1));
      const isAlreadyActive = this.classList.contains('active');
      dropdownLinks.forEach(link => {
        link.classList.remove('active');
        link.setAttribute('aria-expanded', 'false');
      });
      dropdownInnerElements.forEach(el => el.classList.remove('open'));
      if (!isAlreadyActive) {
        dropdownLinks.forEach(link => {
          if (link.getAttribute('href') === this.hash) {
            link.classList.add('active');
            link.setAttribute('aria-expanded', 'true');
          }
        });
        targetElement.classList.add('open');
      }
      if (this.classList.contains('js-hamburger')) {
        if (this.classList.contains('active')) {
          document.body.classList.add('no-scroll-dropdown');
        } else {
          document.body.classList.remove('no-scroll-dropdown');
        }
      }
      e.preventDefault();
    }

    function handleMouseUpTouchStartKeyUp(e) {
      const isInsideDropdown = Array.from(dropdownLinks)
        .concat(Array.from(dropdownInnerElements))
        .some(el => el.contains(e.target));
      if (!isInsideDropdown || e.key === 'Escape') {
        dropdownLinks.forEach(link => {
          link.classList.remove('active');
          link.setAttribute('aria-expanded', 'false');
          document.body.classList.remove('no-scroll-dropdown');
        });
        dropdownInnerElements.forEach(el => el.classList.remove('open'));
      }
    }

    dropdownLinks.forEach(link => link.addEventListener('click', dropdownClick));
    document.addEventListener('mouseup', handleMouseUpTouchStartKeyUp);
    document.addEventListener('touchstart', handleMouseUpTouchStartKeyUp);
    document.addEventListener('keyup', handleMouseUpTouchStartKeyUp);
  };

  dropdown('.js-dropdown-link', '.js-dropdown');


  // animation
  AOS.init({
    once: true
  });


});